<template>
  <div class="container">
    <patient-head :loading="loading" :info="info" small>
      <el-button type="primary" :disabled="loading.plan" @click="save"
        >保存变更</el-button
      >
    </patient-head>
    <el-row :gutter="10">
      <el-col :md="24" :lg="6">
        <div class="card" v-loading="loading.plan">
          <div class="sub-title mt-5" style="margin-bottom: 15px">基础信息</div>
          <el-form label-width="70px">
            <el-form-item label="时间起点">
              <el-select
                v-model="plan.based"
                fit-input-width
                placeholder="请选择时间起点"
                style="width: 110px; margin-right: 4px"
              >
                <el-option label="手术日期" :value="1"></el-option>
                <el-option label="出院日期" :value="2"></el-option>
              </el-select>
              <el-button @click="redo" plain>重构计划</el-button>
            </el-form-item>
            <el-form-item label="患者提示">
              <el-input
                v-model="plan.tips"
                type="textarea"
                :rows="5"
                resize="none"
                maxlength="200"
                placeholder="显示在患者计划页面最顶部, 最多100个字符"
              ></el-input>
            </el-form-item>
            <el-form-item label="计划备注" class="mb-10">
              <el-input
                v-model="plan.remarks"
                type="textarea"
                :rows="5"
                resize="none"
                maxlength="200"
                placeholder="患者不可见, 最多100个字符"
              ></el-input>
            </el-form-item>
            <el-form-item label="上次更新" class="mb-0">
              {{ this.plan.lastTime }}
            </el-form-item>
            <el-form-item label="创建时间" class="mb-0">
              {{ this.plan.addTime }}
            </el-form-item>
          </el-form>
        </div>
      </el-col>
      <el-col :md="24" :lg="18">
        <div style="display: flex">
          <div class="card data-card mb-0" v-loading="loading.plan">
            <div style="height: 100%">
              <div class="sub-title">
                <div>随访节点</div>
                <el-button
                  type="primary"
                  size="small"
                  @click="switchModel('add')"
                >
                  <el-icon><plus /></el-icon>
                  <span>添加随访节点</span>
                </el-button>
              </div>
              <el-cascader-panel
                v-model="select"
                class="cascader"
                :options="list"
                @expand-change="selectChange"
                @change="selectChange"
              >
                <template #default="{ node, data }">
                  <div class="line1 cascader-item">
                    {{ data.label }}
                    <span v-if="!node.isLeaf">
                      ({{ data.children.length }})
                    </span>
                  </div>
                  <div v-if="node.isLeaf" class="line1 cascader-date">
                    {{ data.date }}
                    <el-icon
                      v-if="data.state === 1 || data.state === 2"
                      class="plan-icon"
                      :class="{
                        green: data.state === 1,
                        red: data.state === 2,
                      }"
                    >
                      <circle-check-filled v-if="data.state === 1" />
                      <circle-close-filled v-else-if="data.state === 2" />
                    </el-icon>
                  </div>
                </template>
              </el-cascader-panel>
            </div>
          </div>
          <div class="card edit-card ml-10 mb-0" v-loading="loading.plan">
            <div class="sub-title mt-5" style="margin-bottom: 15px">
              <div v-if="item.model === 'edit'">节点详情</div>
              <div v-else-if="item.model === 'add'">添加节点</div>
            </div>
            <div v-if="item.form.day !== undefined">
              <el-form label-width="70px">
                <el-form-item label="距离天数">
                  {{ plan.based == 1 ? "术后" : "出院" }}
                  <el-input-number
                    v-model="item.form.day"
                    step-strictly
                    :min="0"
                    :max="18250"
                    class="ml-10 mr-10"
                  />天
                </el-form-item>
                <el-form-item label="节点名称">
                  <el-input
                    v-model="item.form.label"
                    style="width: 180px; margin-right: 5px"
                  ></el-input>
                  <el-button @click="buildName()" plain>根据天数生成</el-button>
                </el-form-item>
                <el-form-item label="宽限期限" class="mb-15">
                  向前<el-input-number
                    v-model="item.form.prefix"
                    step-strictly
                    :min="0"
                    :max="365"
                    class="ml-10 mr-10"
                    style="width: 120px"
                  />天, 向后
                  <el-input-number
                    v-model="item.form.suffix"
                    step-strictly
                    :min="0"
                    :max="365"
                    class="ml-10 mr-10"
                    style="width: 120px"
                  />天
                </el-form-item>
                <el-form-item label="全面复查" class="mb-15">
                  <el-switch
                    v-model="item.form.overall"
                    inline-prompt
                    active-text="是"
                    inactive-text="否"
                  />
                </el-form-item>
                <el-form-item label="备注说明" class="mb-15">
                  <el-input
                    v-model="item.form.remarks"
                    type="textarea"
                    :rows="2"
                    resize="none"
                    maxlength="110"
                    placeholder="患者可见, 最多110个字符"
                  ></el-input>
                </el-form-item>
              </el-form>
              <el-transfer
                v-model="item.form.index"
                filterable
                :titles="['可选检测项', '已选检测项']"
                :format="{
                  noChecked: '${total}',
                  hasChecked: '${checked}/${total}',
                }"
                :props="{
                  key: 'id',
                  label: 'name',
                }"
                :data="group"
              />
              <div class="mt-10" v-if="item.model === 'edit'">
                <el-button type="danger" @click="removeItem">删除</el-button>
                <el-button
                  class="float-right"
                  type="primary"
                  style="width: 100px"
                  @click="saveItem"
                  >修改</el-button
                >
              </div>
              <div class="mt-10" v-else-if="item.model === 'add'">
                <el-button @click="switchModel('edit')" plain>取消</el-button>
                <el-button
                  class="float-right"
                  type="primary"
                  style="width: 100px"
                  @click="addItem"
                  >添加</el-button
                >
              </div>
            </div>
            <el-empty
              v-else
              description="请先选择具体的随访节点"
              style="margin: 170px 0 174px 0"
            ></el-empty>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PatientHead from "../../components/patient/patient-head.vue";
import { common, patient, follow } from "../../plugin/api";
import { date } from "../../plugin/util";

import {
  Plus,
  CircleCheckFilled,
  CircleCloseFilled,
} from "@element-plus/icons";

export default {
  name: "PatientPlan",
  components: {
    PatientHead,
    Plus,
    CircleCheckFilled,
    CircleCloseFilled,
  },
  data: () => ({
    loading: {
      info: true,
      plan: true,
      error: false,
    },
    info: {},
    plan: {},
    item: {
      model: "edit",
      select: [],
      form: {},
    },
    select: [],
    group: [],
    cache: "",
    list: [],
  }),
  methods: {
    init() {
      this.loading = {
        info: true,
        plan: true,
        error: false,
      };
      this.info = {
        id: this.$route.params.id,
        name: "",
      };
      this.initCache();
      this.queryInfo();
    },
    initCache() {
      let cache = localStorage.getItem("cache_index_group");
      if (cache) this.group = JSON.parse(cache);
      common.initPage("/template/info").then((res) => {
        if (res.state) {
          localStorage.setItem("cache_index_group", JSON.stringify(res.data));
          this.group = res.data;
        }
      });
    },
    queryInfo() {
      patient
        .getInfo(this.info.id,false)
        .then((res) => {
          if (res.state) {
            setTimeout(() => {
              this.info = res.data.info;
              this.loading.info = false;
              if (res.data.plan) {
                this.plan = res.data.plan;
                if (this.plan.lastTime)
                  this.plan.lastTime = date.format(
                    this.plan.lastTime,
                    "yyyy-mm-dd HH:MM:SS"
                  );
                if (this.plan.addTime)
                  this.plan.addTime = date.format(
                    this.plan.addTime,
                    "yyyy-mm-dd HH:MM:SS"
                  );
                this.cache = JSON.stringify(res.data.plan);
              } else {
                this.loading.error = true;
              }
            }, 300);
            setTimeout(() => {
              if (this.plan.content && this.plan.content.length > 0)
                this.buildList();
              this.loading.plan = false;
            }, 800);
          } else {
            this.loading = {
              info: false,
              plan: false,
              error: true,
            };
          }
        })
        .catch(() => {
          this.loading = {
            info: false,
            plan: false,
            error: true,
          };
        });
    },
    buildList() {
      let list = [];
      let cache = {};
      let content = this.plan.content;
      // 按照距离天数重新排序
      content.sort(function (a, b) {
        return a.day - b.day;
      });
      for (let i in content) {
        let day = content[i].day;
        // 计算年份
        let year = parseInt(day % 365 === 0 ? day / 365 : day / 365 + 1);
        let tag = "第" + year + "年";
        content[i].value = parseInt(i) + 1;
        if (cache[tag] === undefined) cache[tag] = [content[i]];
        else cache[tag].push(content[i]);
      }
      for (let key in cache) {
        list.push({
          value: key,
          label: key,
          children: cache[key],
        });
      }
      this.list = list;
    },
    buildName(input) {
      let name = "";
      let prefix = "术后";
      if (this.plan.based === 2) prefix = "出院";
      let day = this.item.form.day;
      if (input) day = input;
      if (day === 0) name = prefix + "当天";
      else if (day < 365) name = prefix + day + "天";
      else {
        let year = parseInt(day / 365);
        day = day % 365;
        let month = parseInt(day / 30);
        if (day >= 180 && day < 185) name = prefix + year + "年半";
        else if (day === 0) name = prefix + year + "年";
        else if (day % 30 === 0) name = prefix + year + "年零" + month + "个月";
        else name = prefix + year + "年零" + day + "天";
      }
      if (input) return name;
      this.item.form.label = name;
    },
    selectChange(e) {
      if (e[1]) {
        let json = JSON.stringify(this.plan.content[e[1] - 1]);
        this.item = {
          select: e,
          model: "edit",
          form: JSON.parse(json),
        };
      } else {
        this.select = e;
        this.item = {
          model: "edit",
          select: e,
          form: {},
        };
      }
    },
    switchModel(model) {
      if (model === "add") {
        this.item.form = {
          label: "",
          day: 0,
          prefix: 0,
          suffix: 0,
          overall: false,
          remarks: "",
          index: [],
          state: 0,
          date: "",
          timestamp: 0,
        };
      } else {
        if (this.item.select[1] != undefined)
          this.selectChange(this.item.select);
        else this.selectChange([0]);
      }
      this.item.model = model;
    },
    getIndex() {
      let number = 0;
      for (let a in this.list) {
        let year = this.list[a].value;
        for (let b in this.list[a].children) {
          number++;
          if (this.list[a].children[b].day === this.item.form.day)
            return [year, number];
        }
      }
      return undefined;
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    calculationDate(form) {
      let start = this.info.surgeryDate;
      if (this.plan.based == 2) start = this.info.dischargeDate;
      start = parseInt(start) + parseInt(form.day) * 1000 * 60 * 60 * 24;
      form.date = date.format(start, "yyyy-mm-dd");
      form.timestamp = new Date(form.date + " 00:00:00").getTime();
    },
    addItem() {
      if (this.item.form.label.trim() === "")
        return this.showWarning("节点名称不能为空");
      if (this.item.form.index.length === 0)
        return this.showWarning("检测项目不能为空");
      if (this.getIndex() === undefined) {
        this.select = [];
        this.loading.plan = true;
        this.calculationDate(this.item.form);
        this.plan.content.push(this.item.form);
        this.$message.success({
          message: "添加成功, 点击’保存变更‘后生效",
          center: true,
        });
        this.buildList();
        setTimeout(() => {
          this.select = this.getIndex();
          this.selectChange(this.select);
          this.loading.plan = false;
        }, 300);
      } else {
        this.showWarning("当前距离天数已存在");
      }
    },
    saveItem() {
      let edit = false;
      let old = this.plan.content[this.item.select[1] - 1];
      for (let key in this.item.form) {
        if (
          (key !== "index" && this.item.form[key] !== old[key]) ||
          (key === "index" &&
            JSON.stringify(this.item.form[key]) !== JSON.stringify(old[key]))
        )
          edit = true;
      }
      if (edit) {
        this.loading.plan = true;
        this.item.form.state = 0;
        this.calculationDate(this.item.form);
        this.plan.content[this.item.select[1] - 1] = this.item.form;
        this.buildList();
        this.select = [];
        this.$message.success({
          message: "节点详情修改成功, 点击’保存变更‘后生效",
          center: true,
        });
        setTimeout(() => {
          this.select = this.getIndex();
          this.selectChange(this.select);
          this.loading.plan = false;
        }, 300);
      } else {
        this.showWarning("节点详情没有变动");
      }
    },
    removeItem() {
      this.$confirm(
        "删除后只要未点击右上角的‘保存变更’就可以通过刷新页面来找回节点.",
        "删除节点",
        {
          confirmButtonText: "确认删除",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.plan.content.splice(this.item.select[1] - 1, 1);
        this.item.select.pop();
        this.item.form = {};
        this.buildList();
        this.$message.success({
          message: "节点删除成功, 点击’保存变更‘后生效",
          center: true,
        });
      });
    },
    save() {
      let form = JSON.parse(JSON.stringify(this.plan));
      for (let i in form.content) form.content[i].value = undefined;

      if (this.cache !== JSON.stringify(form)) {
        this.$confirm(
          "您修改了随访计划, 是否保存变更, 保存后将无法撤回!",
          "保存变更",
          {
            confirmButtonText: "确认保存",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          let data = {};
          let old = JSON.parse(this.cache);
          if (form.based != old.based) data.based = form.based;
          if (form.tips != old.tips) data.tips = form.tips;
          if (form.remarks != old.remarks) data.remarks = form.remarks;
          if (JSON.stringify(form.content) != JSON.stringify(old.content)) {
            for (let i in form.content) form.content[i].state = undefined;
            data.content = JSON.stringify(form.content);
          }
          data.id = this.plan.id;
          data.pid = this.info.id;
          this.loading.plan = true;
          follow
            .updatePlan(data)
            .then((res) => {
              this.loading.plan = true;
              if (res.state) {
                this.$message.success({
                  message: "保存成功",
                  center: true,
                });
                this.$router.back();
              } else {
                this.loading.plan = false;
                this.showWarning("保存失败");
              }
            })
            .catch(() => {
              this.loading.plan = false;
            });
        });
      } else this.showWarning("随访计划没有变动");
    },
    redo() {
      let label = "手术日期";
      if (this.plan.based === 2) label = "出院日期";
      this.$confirm(
        "此操作将使用“" +
          label +
          "”为起点, 重新计算随访计划每个节点的日期, 并生成新的“节点名称”; 请注意! 此操作会覆盖现有计划中的“节点名称”",
        "重构计划",
        {
          confirmButtonText: "继续",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.loading.plan = true;
        setTimeout(() => {
          for (let i in this.plan.content) {
            this.calculationDate(this.plan.content[i]);
            this.plan.content[i].label = this.buildName(
              this.plan.content[i].day
            );
          }
          this.buildList();
        }, 500);
        setTimeout(() => {
          this.loading.plan = false;
        }, 1600);
      });
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name === "PatientPlan") this.init();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.data-card {
  height: 660px;
  align-items: center;
  display: flex;
}

.sub-title {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  line-height: 16px;
  font-size: 16px;
  display: flex;
  color: #222;
}

.cascader {
  height: 620px;
  justify-content: center;
  width: 360px;
}

.cascader-item {
  display: block;
  width: 125px;
}

.cascader-date {
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
  line-height: 14px;
  font-size: 14px;
  color: #999;
  display: flex;
}

.edit-card {
  height: max-content;
  width: 445px;
}

.green.plan-icon {
  color: #1db100;
}

.red.plan-icon {
  color: #db0000;
}
</style>

<style>
.cascader .el-cascader-menu__wrap {
  height: calc(100vh - 270px);
  width: 180px;
}

.cascader .el-cascader-node {
  height: auto;
}

.edit-card .el-transfer__buttons {
  padding: 0 2.5px;
  width: 40px;
}

.edit-card .el-transfer__buttons > button {
  padding: 8px 12px;
  margin: 5px 0;
}

.edit-card .el-transfer-panel__filter {
  margin: 5px;
}

.edit-card .el-transfer-panel__filter > input {
  border-radius: 6px;
}

.edit-card .el-transfer-panel__list {
  height: calc(100% - 40px);
}

.edit-card .el-transfer-panel__header .el-checkbox__label {
  font-size: 14px !important;
}
</style>