<template>
  <div class="card pb-0" :style="{'min-height': small?'115px':'185px'}">
    <div class="patient-head">
      <div class="title">
        <div class="btn" @click="$router.back()">
          <el-icon><arrow-left /></el-icon>
          <span>返回</span>
        </div>
        <span>
          {{ info.name }} <span class="color-gray">#{{ info.id }}</span>
        </span>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
    <div v-if="!loading.error" v-loading="loading.info">
      <template v-if="small"
        ><el-descriptions :column="4">
          <el-descriptions-item label="性别" width="200px">{{
            info.gender === 1 ? "男" : "女"
          }}</el-descriptions-item>
          <el-descriptions-item label="年龄" width="230px"
            >{{ getAge(info.birthday) }}岁</el-descriptions-item
          >
          <el-descriptions-item label="出生日期" width="220px">{{
            buildDate(info.birthday, "yyyy-mm-dd")
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            info.phone
          }}</el-descriptions-item>
          <el-descriptions-item label="移植类型">{{
            info.transplantTypeName
          }}</el-descriptions-item>
          <el-descriptions-item label="入院日期">-</el-descriptions-item>
          <el-descriptions-item label="手术日期">{{
            buildDate(info.surgeryDate, "yyyy-mm-dd")
          }}</el-descriptions-item>
          <el-descriptions-item label="出院日期">{{
            buildDate(info.dischargeDate, "yyyy-mm-dd")
          }}</el-descriptions-item>
        </el-descriptions></template
      >
      <template v-else>
        <el-descriptions :column="4">
          <el-descriptions-item label="性别" width="200px">{{
            info.gender === 1 ? "男" : "女"
          }}</el-descriptions-item>
          <el-descriptions-item label="年龄" width="230px"
            >{{ getAge(info.birthday) }}岁</el-descriptions-item
          >
          <el-descriptions-item label="出生日期" width="220px">{{
            buildDate(info.birthday, "yyyy-mm-dd")
          }}</el-descriptions-item>
          <el-descriptions-item label="联系电话">{{
            info.phone
          }}</el-descriptions-item>
          <el-descriptions-item label="证件类型">{{
            info.certTypeName
          }}</el-descriptions-item>
          <el-descriptions-item label="证件号码">{{
            info.certId
          }}</el-descriptions-item>
          <el-descriptions-item label="家庭地址">{{info.address}}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :column="4">
          <el-descriptions-item label="主管医生" width="200px">{{
            info.supervisorName
          }}</el-descriptions-item>
          <el-descriptions-item label="入院日期" width="230px"
            >-</el-descriptions-item
          >
          <el-descriptions-item label="手术日期" width="220px">{{
            buildDate(info.surgeryDate, "yyyy-mm-dd")
          }}</el-descriptions-item>
          <el-descriptions-item label="出院日期">{{
            buildDate(info.dischargeDate, "yyyy-mm-dd")
          }}</el-descriptions-item>
          <el-descriptions-item label="手术医生">{{
            info.surgeryDoctorName
          }}</el-descriptions-item>
          <el-descriptions-item label="移植类型">{{
            info.transplantTypeName
          }}</el-descriptions-item>
          <el-descriptions-item label="伴随疾病">-</el-descriptions-item>
        </el-descriptions>
      </template>
    </div>
    <el-result v-else icon="error" title="加载失败" />
  </div>
</template>

<script>
import { ArrowLeft } from "@element-plus/icons";
import { date } from "../../plugin/util";

export default {
  name: "PatientHead",
  components: { ArrowLeft },
  props: {
    loading: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  methods: {
    buildDate(data, code) {
      return date.format(data, code);
    },
    getAge(number) {
      let now = new Date();
      let birthdays = new Date(parseInt(number) * 1000);
      return (
        now.getFullYear() -
        birthdays.getFullYear() -
        (now.getMonth() < birthdays.getMonth() ||
        (now.getMonth() == birthdays.getMonth() &&
          now.getDate() < birthdays.getDate())
          ? 1
          : 0)
      );
    },
  },
};
</script>

<style scoped>
.patient-head {
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  width: 100%;
}

.patient-head .title {
  align-items: center;
  display: flex;
}

.patient-head .title > span {
  margin-left: 10px;
  font-size: 18px;
}

.btn {
  background-color: #f4f4f4;
  color: #222;
}

.btn:hover {
  background-color: #e2e2e2;
  color: #222;
}

.btn:active,
.btn.active {
  background-color: #d4d3d3;
  color: #222;
}
</style>